<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 mb-2 bg-image " data-bs-bg=''>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ categories.heading }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="/" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li>{{ categories.heading }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ltn__category-area ltn__product-gutter section-bg-1--- pb-70">
      <div class="container">
        <div class="row ltn__category-slider-active--- slick-arrow-1 justify-content-center rowEQ">
          <div v-for="item in productsF" class="col-lg-3 col-md-4 col-sm-6 col-6 EQcol">
            <div class="ltn__category-item ltn__category-item-5 ltn__category-item-5-2 text-center---">
              <router-link :to="'/urun/'+item.seo">
                <div>
                  <img :src="item.img" :alt="item.name">
                </div>
                <span class="category-title">{{ item.name }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import iso from "../../axios";
export default {
  props:['langcontent'],
  data() {
    return {
      categories:[],
      products:[],
      productsF:[],
      cats:'bos',
    }
  },
  methods:{
     pGet(){
      iso.get('categories/'+this.$route.params.mId)
          .then(response => {
            this.categories = response.data
            this.cats = response.data.id
            iso.get('product')
                .then(response => {
                  this.products = response.data
                  this.productsF = this.products.filter((x) => {
                    return (x.categories == this.cats)
                  })
                  console.log(this.productsF)
                })
                .catch(error => {
                  console.log(error);
                })
          })
          .catch(error => {
            console.log(error);
          })
    }
  },
  mounted() {
    this.pGet()

  },
  watch:{
    $route (to, from){
        this.pGet()
    }


  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>